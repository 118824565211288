import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/modules/nonbmaWave"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"

const wavelpData = import("./data/data.json");


export default function tideSpa() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`


       .footerlinks-module--br {
         display: none!important;
       }

       #ctadisclosuresearch-module--ctadisclaimersearch {
         width: 50%;
         margin: 0 auto;
       }

       .wave-module--wave .wave-module--header img {
         padding: 8px 0 8px 8px;
         width: 35px;
       }

       .wave-module--wave .wave-module--center {
         width: 100%;
       }

       .wave-module--wave ul.wave-module--bullets li {
         padding-bottom: 10px;
         flex: 0 1 45%!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         width: 100%;
    max-width: 850px;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Find Manuals to Read & Print - Freshy Search</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}>
      <CtaDisclosureSearch></CtaDisclosureSearch></NonbmaWave>
      </section>
    </HomepageLayout>
  )
}
